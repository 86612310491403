
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import Header from '@/components/organisms/Header.vue'
import Navigation from '@/components/organisms/Navigation.vue'
import { ActionTypes as AuthActionTypes } from '@/store/auth/actions'

export default defineComponent({
  name: 'App',
  components: {
    Header,
    Navigation
  },
  setup() {
    const store = useStore()
    const isLoggedIn = computed(() => store.state.auth.jwt.length > 0)
    const logout = () => {
      store.dispatch(AuthActionTypes.LOGOUT)
    }
    const links = [
      {
        routerName: 'superAdminDashboard',
        routerText: 'Dashboard',
        icon: 'Placeholder--active.svg',
        menuType: 'dashboard'
      },
      { routerName: 'studios', routerText: 'Studios', icon: 'Tasks.svg', menuType: 'studios' },
      { routerName: 'users', routerText: 'Users', icon: 'Clients.svg', menuType: 'clients' },
      { routerName: 'analytics', routerText: 'Analytics', icon: 'Projects.svg', menuType: 'projects' },
      { routerName: 'payments', routerText: 'Payments', icon: 'Placeholder.svg', menuType: 'books' },
      { routerName: 'activity', routerText: 'Activity', icon: 'Placeholder.svg', menuType: 'books' },
      { routerName: 'apps', routerText: 'Apps', icon: 'Placeholder.svg', menuType: 'books' }
    ]
    return { isLoggedIn, links, logout }
  }
})
