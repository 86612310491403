<template>
  <Header @logout="logout" :isLoggedIn="isLoggedIn" headerClass="superAdmin" />
  <div class="main">
    <Navigation :links="links" />
    <div class="page-wrap">
      <router-view />
    </div>
  </div>
  <!-- <div v-if="isLoggedIn" class="main">
    <Navigation :links="links" />
    <div class="page-wrap">
      <router-view />
    </div>
  </div>
  <div v-else>
    <router-view />
  </div> -->
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import Header from '@/components/organisms/Header.vue'
import Navigation from '@/components/organisms/Navigation.vue'
import { ActionTypes as AuthActionTypes } from '@/store/auth/actions'

export default defineComponent({
  name: 'App',
  components: {
    Header,
    Navigation
  },
  setup() {
    const store = useStore()
    const isLoggedIn = computed(() => store.state.auth.jwt.length > 0)
    const logout = () => {
      store.dispatch(AuthActionTypes.LOGOUT)
    }
    const links = [
      {
        routerName: 'superAdminDashboard',
        routerText: 'Dashboard',
        icon: 'Placeholder--active.svg',
        menuType: 'dashboard'
      },
      { routerName: 'studios', routerText: 'Studios', icon: 'Tasks.svg', menuType: 'studios' },
      { routerName: 'users', routerText: 'Users', icon: 'Clients.svg', menuType: 'clients' },
      { routerName: 'analytics', routerText: 'Analytics', icon: 'Projects.svg', menuType: 'projects' },
      { routerName: 'payments', routerText: 'Payments', icon: 'Placeholder.svg', menuType: 'books' },
      { routerName: 'activity', routerText: 'Activity', icon: 'Placeholder.svg', menuType: 'books' },
      { routerName: 'apps', routerText: 'Apps', icon: 'Placeholder.svg', menuType: 'books' }
    ]
    return { isLoggedIn, links, logout }
  }
})
</script>

<style lang="sass">
.main
  display: flex
  flex: 1
  @media( max-width: $md-breakpoint)
    flex-direction: column
.page-wrap
  flex: 1
  display: flex
  flex-direction: column
</style>
